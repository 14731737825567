import React from 'react'
import footerImg from  '../../assets/images/img/footer.png'

export default function Newsletter() {
  return (
    <div className='newsletter-section'>
      <div className="container">
            <div className="row m-0">
                <div className="col-md-6">
                    <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                        <div className='d-flex flex-column gap-3'>
                            <div className='d-flex gap-2 center-heading'>
                                <h3 className="h3 text-white">
                                     Newsletter
                                </h3>
                                <hr />
                            </div>
                            <h2 className="h2 text-white center-heading">Subscribe our <span>Newsletter</span></h2>
                            <div className='newsletterEmail-div d-flex'>
                                <input type="email" name="newsletterEmail" id="newsletterEmail" placeholder='Enter your email address' />
                                <div>
                                    <button type="submit">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                        <div className='bg d-flex justify-content-center align-items-center'>
                            <img src={footerImg} alt="robot head" />
                        </div>
                </div>
            </div>    
      </div>
    </div>
  )
}
