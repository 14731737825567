import React from 'react'

export default function Pricing_Plan_section() {
  return (
    <div className="main-bg contact-page">
        <div className="about-bg d-flex justify-content-center align-items-center">
        <h1 className="h1 text-white">Pricing Plan</h1>
        </div>
      
    </div>
  )
}
