import React from 'react'
import Card from "../reuse/Card";
import Insights_img1 from  "../../assets/images/img/insight1.png";
import Insights_img2 from  "../../assets/images/img/insight2.png";
import Insights_img3 from  "../../assets/images/img/insight3.png";
import Insights_img4 from  "../../assets/images/img/insight4.png";
export default function Insights() {
  return (
    <div className="insights-section">
        <div className='container'>
            <div className='d-flex flex-column gap-3 gap-md-4 gap-xxl-5'>
                <div className="heading d-flex flex-column gap-2">
                    <div className='d-flex gap-2'>
                        <h3 className="h3 text-white">
                            Insights
                        </h3>
                        <hr />
                    </div>
                    <h2 className="h2 text-white">
                        Tapping <span>Intelligence</span> Through Talent.
                    </h2>
                </div>
                <div className='insights-cards '>
                    <Card card_main_image={Insights_img1} card_title="3 Cobot Success Stories"card_icon={null} discription="Lorem Ipsum is simply dummy text of the printing and ."/>
                    <Card card_main_image={Insights_img2} card_icon={null} card_title="Boost Your Cobot Safety Know." discription="Lorem Ipsum is simply dummy text of the printing ."/>
                    <Card card_main_image={Insights_img3} card_icon={null} card_title="Self-Driving Vehicle Success Is Tied." discription="Lorem Ipsum is simply dummy text of the printing and ."/>
                    <Card card_main_image={Insights_img4} card_icon={null} card_title="Justifying Automation Factory." discription="Lorem Ipsum is simply dummy text of the printing and ."/>
                </div>
            </div>
        </div>
    </div>
  )
}
