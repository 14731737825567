import React from 'react'
import contactImg from '../../assets/images/img/contact.png'

export default function Contact() {
  return (
    <div className='contact-section'>
      <div className="container">
        <div className="row m-0">
          <div className="col-md-6">
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
              <div className="d-flex justify-content-center align-items-center h-100">
                <img className='bg' src={contactImg} alt="contact-image"/>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div  className="d-flex flex-column gap-3">
              <div className="d-flex gap-3 h3-div">
                <h3 className="h3 text-white">Contact</h3><hr />
              </div>
              <h2 className="h2 text-white">Be Well, Stay Clam and <span>Keep In Touch</span></h2>
              <div className='contact-form-div'>
                <form action="">
                  <div className='d-flex flex-column gap-3'>
                      <div className='d-flex gap-3'>
                        <input type="text" name="firstName" id="firstName" placeholder='First Name' required/>
                        <input type="text" name="lastName" id="lastName" placeholder='Last Name' required/>
                      </div>
                      <div className='d-flex gap-3'>
                        <input type="email" name="email" id="email" placeholder='Email Address' required/>
                        <input type="number" name="phoneNumber" id="phoneNumber" placeholder='Phone Number' required/>
                      </div>
                        <textarea name="message" id="message" rows={6}  placeholder='Message'  required></textarea>
                      <div>
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
