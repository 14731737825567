import React from "react";
import Faq_section from "../home/Faq_section";

export default function Faq_page_section() {
  return (
    <div className="main-bg contact-page">
      <div className="about-bg d-flex justify-content-center align-items-center">
        <h1 className="h1 text-white">FAQ's</h1>
      </div>
      <div  id="accordionFlushExample">
        <Faq_section />
        <div className="container">
          <div
            className="accordion accordion-flush d-flex flex-column gap-3"
           
          >
            <div className="row">
              <div className="col-md-6 d-flex flex-column gap-3">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      What is Lorem Ipsum?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      How Robots Work?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      Are They Beneficial for Us?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  style={{ borderBottom: "1px solid #00ffb2" }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      How Can I Get Them?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 d-flex flex-column gap-3">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      What is Lorem Ipsum?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTen"
                      aria-expanded="false"
                      aria-controls="flush-collapseTen"
                    >
                      How Robots Work?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTen"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEleven"
                      aria-expanded="false"
                      aria-controls="flush-collapseEleven"
                    >
                      Are They Beneficial for Us?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEleven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  style={{ borderBottom: "1px solid #00ffb2" }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwelve"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwelve"
                    >
                      How Can I Get Them?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwelve"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam hendrerit aliquet fermentum. Nulla non metus
                      sapien. Quisque nec aliquet ante.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
