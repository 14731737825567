import React from 'react'
import { Link } from 'react-router-dom'
// import Team1 from '../../assets/images/img/team1.png'

export default function Team_members(props) {
  return (
    <div className='team-member'>
      <div className='d-flex justify-content-center align-items-end mt-3'>
            <img src={props.team_members_image} alt="Team" />
      </div>
      <div>
            <div className='d-flex flex-column align-items-center gap-2 gap-md-3 gap-xxl-4'>
                <h3 className="h3 text-white m-0">{props.team_members_name}</h3>
                <h4 className="h4 text-white m-0">{props.team_members_position}</h4>
                <div className='d-flex gap-3 gap-lg-5 align-items-center '>
                    <i className="fa-brands fa-instagram social-icons"></i><Link to="https://www.facebook.com/profile.php?id=100007216876977"></Link>
                    <i className="fa-brands fa-facebook-f social-icons"></i><Link to=""></Link>
                    <i className="fa-brands fa-whatsapp social-icons"></i><Link to=""></Link>
                </div>
            </div>
      </div>
    </div>
  )








  
}

