import React from 'react'
import Pricing_Plan_section from './Pricing_Plan_section'
import Pricing from '../home/Pricing'
import Counter from '../home/Counter'
import Contact from '../home/Contact'
import Navbar from '../common/Navbar'
import Newsletter from '../common/Newsletter'
import Footer from '../common/Footer'

export default function Pricing_Plan() {
  return (
    <div className='bg-clr'>
      <Navbar/>
      <Pricing_Plan_section/>
      <Pricing/>
      <Counter/>
      <Contact/>
      <Newsletter/>
      <Footer/>
    </div>
  )
}
