import React from "react";
import Servicessection from '../home/Services';
import Why_Us from "../About/Why_Us";
import Testimonial from "../home/Testimonial";
import Faq from "../home/Faq_section";
import Discover from "../home/Discover";
import Contact from "../home/Contact";
import Navbar from "../common/Navbar";
import Newsletter from "../common/Newsletter";
import Footer from "../common/Footer";

export default function Services() {
  return (
    <div className="main-bg ">
      <Navbar/>
      <div className="about-bg d-flex justify-content-center align-items-center">
        <h1 className="h1 text-white">Services</h1>
      </div>
      <Servicessection/>
      <Why_Us/>
      <Testimonial/>
      <Faq/>
      <Discover/>
      <Contact/>
      <Newsletter/>
      <Footer/>
    </div>
  );
}
