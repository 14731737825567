import React from 'react'
import learnMoreImg from '../../assets/images/icons/circle.png'
import homeRobotImg from '../../assets/images/img/New update available.png'

export default function Main() {
  return (
    <div className='home'>
      <div className="main">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="h-100 d-flex flex-column justify-content-center gap-3 home-main-title">
                        <h1 className="h1 home-title">Let's Company <span>WildCodeX</span> Handle It.</h1>
                        <p className="home-para">lorem ipsum dolor sit amet, consectetur adipicsing elit, sed do eisumod tempor incidedent ut labore at dolore magna aliqua.</p>
                        <a href="/" className="d-flex align-items-center text-decoration-none learn-more" >
                        <img src={learnMoreImg} alt="Learn more circle" />
                        <div className="d-flex align-items-center circle-hover">
                            <i className="fa-solid fa-arrow-right main-right-arrow"></i>
                            <p className='home-para2 '>Learn more</p>
                        </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="home-bg">
                        <img src={homeRobotImg} alt="Home Robot" />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
