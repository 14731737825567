import React from "react";
import { Link } from "react-router-dom";
import coming_soon_img from "../../assets/images/img/coming_soon.png";
import NotFound_image from "../../assets/images/img/404.png";

export default function NotFound() {
  return (
    <div className="coming-soon-page d-flex justify-content-center align-items-center ">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center gap-4">
            <h1 className="h1 text-white">
            Error <span> 404</span>
            </h1>
            <div>
              <Link to="/">
                <button className="coming-soon-btn">Go Back to Home Page</button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div className="bg d-flex justify-content-center align-items-center">
              <img src={NotFound_image} alt="Not Found" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
