import React from 'react'
import location from '../../assets/images/icons/pricing-icon.png'
import phone from '../../assets/images/icons/address2.png'
import mail from '../../assets/images/icons/mail.png'
import { Link } from 'react-router-dom'

export default function Contact() {
  return (
    <div className="main-bg contact-page">
      <div className="about-bg d-flex justify-content-center align-items-center">
        <h1 className="h1 text-white">Contact</h1>
      </div>
      <div className='bg-color'>
        <div className="container">
            <div className="row ">
                <div className="col-md-4 my-2 ">
                    <div className="contact-box">
                        <div className='d-flex flex-column gap-5'>
                            <div>
                                    <img src={location} alt="contact icons" />
                            </div>
                            <div className='d-flex flex-column gap-3'>
                                <h2 className="h2 text-white">
                                Location
                                </h2>
                                <h5 className="h5 text-white">Johar Town Lahore</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 my-2 ">
                <div className="contact-box">
                        <div className='d-flex flex-column gap-5'>
                            <div>
                                    <img src={phone} alt="contact icons" />
                            </div>
                            <div className='d-flex flex-column gap-3'>
                                <h2 className="h2 text-white">
                                Phone
                                </h2>
                                <h5 className="h5 text-white">+923075447452</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                <div className="contact-box">
                        <div className='d-flex flex-column gap-5'>
                            <div>
                                    <img src={mail} alt="contact icons" />
                            </div>
                            <div className='d-flex flex-column gap-3'>
                                <h2 className="h2 text-white">
                                Mail
                                </h2>
                                <h5 className="h5 text-white"><Link to="mailto:contact@wildcodex.com">Contact@wildCodeX.Com</Link>Contact@wildCodeX.Com</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
