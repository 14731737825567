import React from 'react'
import faqImg from '../../assets/images/img/faq.png'

export default function Faq_section(props) {
  return (
    <div className='faq-section '>
        <div className="container">
        <div className="row m-0">
            <div className="col-md-6">
                <div className='d-flex flex-column gap-3 '>
                    <div className="d-flex gap-3 faq-title">
                        <h3 className="h3 text-white">FAQ</h3>
                        <hr />
                    </div>
                    <h2 className="h2 text-white faq-heading">How are Robots <span>Enhancing</span> Health Care?</h2>
                    <div>
                        <div className="accordion accordion-flush d-flex flex-column gap-3"  id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                What is Lorem Ipsum ?
                                </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit aliquet fermentum. Nulla non metus sapien. Quisque nec aliquet ante.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                How Robots Work ?
                                </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit aliquet fermentum. Nulla non metus sapien. Quisque nec aliquet ante.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Did they beneficial for us ?
                                </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit aliquet fermentum. Nulla non metus sapien. Quisque nec aliquet ante.</div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderBottom: "1px solid  #00ffb2"}}>
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                How can I get them ?
                                </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit aliquet fermentum. Nulla non metus sapien. Quisque nec aliquet ante.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className='bg d-flex flex-column justify-content-center align-items-center h-100'>
                <div className="faq-img1">
                    <div className="faq-img2">
                        <img src={faqImg} alt="faq image" />
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
