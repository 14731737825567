import React from 'react'
import Insights_page_section from './Insights_page_section'
import Discover from '../home/Discover'
import Contact from '../home/Contact'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Newsletter from '../common/Newsletter'

export default function Insights_page() {
  return (
    <div>
      <Navbar/>
      <Insights_page_section/>
      <Discover/>
      <Contact/>
      <Newsletter/>
      <Footer/>
    </div>
  )
}
