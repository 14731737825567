import React from "react";
import Team_members from "../reuse/Team_members";
import team_members_image1 from '../../assets/images/img/team1.png'
import team_members_image2 from '../../assets/images/img/team2.png'
import team_members_image3 from '../../assets/images/img/team3.png'

export default function Team() {
  return (
    <div className="team-section">
      <div className="d-flex flex-column align-items-center">
            <div className="d-flex gap-3 ">
            <hr />
            <h3 className="h3 text-white">Team</h3>
            <hr />
            </div>
            <h2 className="h2 text-white">
            Our Certified <span>Scientists</span> With Smart Solutions
            </h2>
      </div>
        <div className="d-flex justify-content-center align-items-center gap-4 flex-column flex-sm-column flex-md-row ">
            <Team_members team_members_image={team_members_image1} team_members_name="Mr Saad" team_members_position="MERN Stack Developer"/>
            <Team_members team_members_image={team_members_image2} team_members_name="Mr Haroon" team_members_position="Networking Manager"/>
            <Team_members team_members_image={team_members_image3} team_members_name="Mr Mehmood" team_members_position="Team Lead Manager"/>
        </div>
    </div>
  );
}
