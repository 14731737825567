import React from 'react'
import logo from '../../assets/images/logo/Asset 3.png';
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className='footer'>
      <div className="container">
        <div>
            <div className='d-flex flex-md-row flex-sm-column flex-column gap-md-0 gap-sm-3 gap-3 justify-content-between text-center  align-items-center'>
                <Link to="/">
               <div className='d-flex justify-content-start align-items-center'>
                    <img src={logo} className='w-25 h-25' alt="footer logo" />
                </div>
                </Link>
                <div className='list-inline d-flex flex-wrap justify-content-center gap-md-3  gap-3'>
                    <a href="/" className='companyPages-footer'>Company</a>
                    <a href="/" className='companyPages-footer'>Contact us</a>
                    <a href="/" className='companyPages-footer'>Privacy Policy</a>
                    <a href="/" className='companyPages-footer'>Cookie Policy</a>
                    <a href="/" className='companyPages-footer'>Terms  of Use</a>
                </div>
            </div>
            <hr />
            <div className="d-flex flex-md-row flex-sm-column flex-column gap-md-0 gap-sm-3 gap-2 justify-content-between align-items-center text-center">
                <div className='d-flex gap-3'>
                    <a href="/" className='text-decoration-none footer-social-tags'><i className="fa-brands fa-instagram footer-social-icons"></i></a>
                    <a href="/" className='text-decoration-none footer-social-tags'><i className="fa-brands fa-facebook-f footer-social-icons"></i></a>
                    <a href="/" className='text-decoration-none footer-social-tags'><i className="fa-brands fa-whatsapp footer-social-icons"></i></a>
                </div>
                <p className="text-white">Copyright © 2024 WildCodeX, All rights reserved. Powered by HOP</p>
            </div>
        </div>
      </div>
    </footer>
  )
}
