import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import inverted_comma from "../../assets/images/icons/inverted comma.png";
import testimonial1 from "../../assets/images/img/testimonial1.png";
import testimonial2 from "../../assets/images/img/testimonial2.png";
import testimonial3 from "../../assets/images/img/testimonial3.png";
import testimonial4 from "../../assets/images/img/testimonial4.png";

export default function Carousel() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div>
        <div className="testimon-card-div">
          <div className="testimon-card">
            <div className="testimonial">
              <div className="d-flex justify-content-between">
                <div className="person-div">
                  <div className="person">
                    <img src={testimonial1} alt="testimonial" />
                  </div>
                </div>
                <div>
                  <img src={inverted_comma} alt="inverted comma" />
                </div>
              </div>
              <div>
                <h5 className="h5 text-white pt-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry.{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="testimon-card-div">
          <div className="testimon-card">
            <div className="testimonial">
              <div className="d-flex justify-content-between">
                <div className="person-div">
                  <div className="person">
                    <img src={testimonial2} alt="testimonial" />
                  </div>
                </div>
                <div>
                  <img src={inverted_comma} alt="inverted comma" />
                </div>
              </div>
              <div>
                <h5 className="h5 text-white pt-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry.{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="testimon-card-div">
          <div className="testimon-card">
            <div className="testimonial">
              <div className="d-flex justify-content-between">
                <div className="person-div">
                  <div className="person">
                    <img src={testimonial3} alt="testimonial" />
                  </div>
                </div>
                <div>
                  <img src={inverted_comma} alt="inverted comma" />
                </div>
              </div>
              <div>
                <h5 className="h5 text-white pt-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry.{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="testimon-card-div">
          <div className="testimon-card">
            <div className="testimonial">
              <div className="d-flex justify-content-between">
                <div className="person-div">
                  <div className="person">
                    <img src={testimonial4} alt="testimonial" />
                  </div>
                </div>
                <div>
                  <img src={inverted_comma} alt="inverted comma" />
                </div>
              </div>
              <div>
                <h5 className="h5 text-white pt-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry.{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
}
