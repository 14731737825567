import React from 'react'
import project1_img from  '../../assets/images/img/project1.png';
import project2_img from  '../../assets/images/img/project2.png';

export default function Our_Project() {
  return (
    <div className='project_bg_dark'>
      <div className='container d-flex flex-column gap-2 gap-md-3 gap-lg-4'>
            <div className="d-flex align-items-center justify-content-center gap-2">
                <hr />
                <h3 className="h3 text-white m-0">Projects</h3>
                <hr />
            </div>
            <h2 className="h2 text-white">Our <span>Project</span></h2>
            <div>
                <div className="row m-0">
                    <div className="col-md-3">
                        <div className="project-bg1 text-center">
                            <img src={project1_img} alt="projects-img" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='project-details-div text-white'>
                                <h4 className="h4 text-white">Avation EMF-01</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                <a href="" className="card-learn-more text-decoration-none d-flex align-items-center gap-1">Learn more<i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className="project-bg1 text-center">
                            <img src={project2_img} alt="projects-img" />
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className='project-details-div text-white'>
                                <h4 className="h4 text-white">Avation EMF-02</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                <a href="" className="card-learn-more text-decoration-none d-flex align-items-center gap-1">Learn more<i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  )
}
