import React from "react";
import coming_soon_img from "../../assets/images/img/coming_soon.png";
import { Link } from "react-router-dom";

export default function Coming_soon() {
  return (
    <div className="coming-soon-page d-flex justify-content-center align-items-center ">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center gap-4">
            <h1 className="h1 text-white">
              Coming <span>Soon</span>
            </h1>
            <div>
             
              <Link to='/'> <button className="coming-soon-btn">Go Back to Home Page</button></Link>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div className="bg d-flex justify-content-center align-items-center">
              <img src={coming_soon_img} alt="Coming Soon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
