import React from 'react'
import Team_members from "../reuse/Team_members";
import team_members_image1 from '../../assets/images/img/team1.png'
import team_members_image2 from '../../assets/images/img/team2.png'
import team_members_image3 from '../../assets/images/img/team3.png'
import team_members_image4 from '../../assets/images/img/team4.png'
import team_members_image5 from '../../assets/images/img/team5.png'
import team_members_image6 from '../../assets/images/img/team6.png'



export default function Team() {
  return (
    <div className="main-bg ">
      <div className="about-bg d-flex justify-content-center align-items-center">
        <h1 className="h1 text-white">Team</h1>
      </div>
      <div className='team-page'>
      <div className='d-flex flex-column gap-3 head align-items-center'>
            <div className='d-flex gap-3 '>
                <hr />
                <h3 className="h3 text-white">Team</h3>
                <hr />
            </div>
            <h2 className="h2 text-white">Our Team in<span> WildCodeX</span> With Smart Solutions</h2>
        </div>
        <div className='d-flex flex-column gap-4'>
            
            {/* <div className="d-flex justify-content-center align-items-center gap-4 flex-column flex-sm-column flex-md-row ">
                <Team_members team_members_image={team_members_image4} team_members_name="Mr Raza Ul Mustafa" team_members_position="CEO"/>
                <Team_members team_members_image={team_members_image5} team_members_name="Mr Zia Ul Mustafa" team_members_position="CTO"/>
                <Team_members team_members_image={team_members_image6} team_members_name="Mr Zaka Ul Mustafa" team_members_position="Operation Manager"/>
            </div> */}
            <div className="d-flex justify-content-center align-items-center gap-4 flex-column flex-sm-column flex-md-row ">
                <Team_members team_members_image={team_members_image1} team_members_name="Mr Saad" team_members_position="MERN Stack Developer"/>
                <Team_members team_members_image={team_members_image2} team_members_name="Mr Haroon" team_members_position="Networking Manager"/>
                <Team_members team_members_image={team_members_image3} team_members_name="Mr Mehmood" team_members_position="Team Manager"/>
            </div>
        </div>
      </div>
    </div>
  )
}
