import React from 'react'

export default function Why_Us() {
  return (
    <>
        <div className="why-us-bg">
            <div className="container">
               <div className="heading">
               <div className="d-flex gap-4 text-center">
                    <h3 className="h3 ">Why Us</h3>
                    <hr />
                </div>
                <h2 className="h2">Our <span>Approach</span></h2>
               </div>
                <div className="d-flex gap-3 flex-column flex-sm-column flex-md-row flex-lg-row">
                    <div className="text-white box d-flex flex-column text-center">
                        <h4 className="h4">Mission</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                    </div>
                    <div className="text-white box d-flex flex-column text-center">
                        <h4 className="h4">Mission</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                    </div>
                    <div className="text-white box d-flex flex-column text-center">
                        <h4 className="h4">Mission</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
