import React from "react";
import About_Us_icon1 from "../../assets/images/icons/about-us-icon-1.png";
import About_Us_icon2 from "../../assets/images/icons/about-us-icon-2.png";
import about_img_section1 from "../../assets/images/img/about1.png";
import about_img_section2 from "../../assets/images/img/about2.png";
import about_img_section3 from "../../assets/images/img/about3.png";
export default function About_Us() {
  return (
    <div className="about-us-div">
      <div className="container">
        <div className="row m-0">
          <div className="col-md-6">
            <div className="d-flex flex-column gap-2 gap-md-3 gap-lg-4 gap-xxl-5">
              <div className="heading">
                <div className="d-flex gap-4">
                  <h3 className="h3 text-white">About Us</h3>
                  <hr />
                </div>
                <h2 className="h2 text-white">
                  Empowering <span>People</span> By Keeping Them Well
                </h2>
              </div>
              <p className="about-para text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s.
              </p>
              <div className="d-flex flex-column gap-4">
                <div className="about-us d-flex gap-2 gap-sm-3 gap-md-4 gap-lg-5">
                  <img src={About_Us_icon1} alt="about icon" />
                  <div className="text-white">
                    <h3 className="h3">Smart Solutions</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </p>
                  </div>
                </div>
                <div className="about-us d-flex gap-2 gap-sm-3 gap-md-4 gap-lg-5">
                  <img src={About_Us_icon2} alt="about icon" />
                  <div className="text-white">
                    <h3 className="h3">Certified Expert</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </p>
                  </div>
                </div>
              </div>
              <button className="about-btn">About</button>
            </div>
          </div>
          <div className="col-md-6 about-section-bg d-flex flex-column justify-content-center mt-5">
            <div className="d-flex gap-4">
              <div className="about-img-section1 d-flex flex-column gap-4">
                <div className="about-img-bg">
                  <img src={about_img_section1} alt="about img1" />
                </div>
                <div className="about-img-bg">
                  <img src={about_img_section2} alt="about img2" />
                </div>
              </div>
              <div className="about-img3-div">
                <div className="about-img3">
                  <img src={about_img_section3} alt="about img3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
