import React from 'react'
import Team from './Team'
import Faq from '../home/Faq_section'
import Discover from '../home/Discover'
import Insights from '../home/Insights'
import Navbar from '../common/Navbar'
import Newsletter from '../common/Newsletter'
import Footer from '../common/Footer'

export default function Team_page() {
  return (
    <div>
      <Navbar/>
      <Team/>
      <Faq/>
      <Discover/>
      <Insights/>
      <Newsletter/>
      <Footer/>
    </div>
  )
}
