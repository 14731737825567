import React from 'react'
import Faq_page_section from './Faq_page_section'
import Counter from '../home/Counter'
import Contact from '../home/Contact'
import Navbar from '../common/Navbar'
import Newsletter from '../common/Newsletter'
import Footer from '../common/Footer'

export default function Faq_page() {
  return (
    <div className='bg-clr'>
      <Navbar/>
      <Faq_page_section/>
      <Counter/>
      <Contact/>
      <Newsletter/>
      <Footer/>
    </div>
  )
}
