import React from "react";
import ServicesImg from "../../assets/images/img/Service.png";
import Card from "../reuse/Card";
import card1_main_img from "../../assets/images/img/card1.png";
import card1_icon from "../../assets/images/icons/card1.png";
import card2_main_img from "../../assets/images/img/card2.png";
import card2_icon from "../../assets/images/icons/card2.png";
import card3_main_img from "../../assets/images/img/card3.png";
import card3_icon from "../../assets/images/icons/card3.png";
import card4_main_img from "../../assets/images/img/card4.png";
import card4_icon from "../../assets/images/icons/card4.png";

export default function Services() {
  return (
    <>
      <div className="services">
        <div className="container">
          <div className="row" id="service-section">
            <div className="col-md-6">
              <div className="d-flex justify-content-center ">
                <img src={ServicesImg} alt="services" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex gap-4 mb-2">
                <h3 className="h3 text-white">Services</h3>
                <hr/>
              </div>
              <h2 className="h2 text-white">
                Promising Care;
                <span> Right At Home</span>
              </h2>
              <p className="text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s,
              </p>
            </div>
          </div>
        </div>
        <div className="container d-flex flex-column gap-5 cards-section">
          <div className="card_list_1 d-flex flex-column flex-sm-column flex-md-row flex-lg-row gap-3">
            <Card card_main_image={card1_main_img} card_icon={card1_icon} card_title="Web Development" discription="Our Company Provide Web Services"/>
            <Card card_main_image={card2_main_img} card_icon={card2_icon} card_title="Graphics Designing" discription="Our Company provide Graphics Services" />
          </div>
          <div className="card_list_1 d-flex flex-column flex-sm-column flex-md-row flex-lg-row gap-3">
            <Card card_main_image={card3_main_img} card_icon={card3_icon} card_title="DevOps" discription="our company provide web services"/>
            <Card card_main_image={card4_main_img} card_icon={card4_icon} card_title="Python DJango" discription="our company provide web services"/>
          </div>
        </div>
      </div>
    </>
  );
}
