import React from "react";
import Carousoel from "./Carousel";

export default function Testimonial() {
  return (
    <div id="testimonial-section">
      <div className="container  d-flex flex-column gap-3 gap-md-4 gap-lg-5">
        <div className="testimonial-head d-flex flex-column justify-content-center align-items-center gap-2 gap-md-3 gap-xxl-4">
          <div className="d-flex gap-2">
            <hr />
            <h3 className="h3 text-white m-0">Testimonial</h3>
            <hr />
          </div>
          <h5 className="h5 text-white">Succour The <span>Customer Better</span></h5>
          <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
        </div>
        <Carousoel />
      </div>
    </div>
  );
}
