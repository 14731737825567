import React from "react";

export default function Counter() {
  return (
    <div className="counter_bg d-flex align-items-center justify-content-center gap-3 gap-md-5 ">
      <div className="counter-div d-flex flex-column align-items-center justify-content-center gap-2">
            <div className="d-flex counter_head">
                <h2 className="h2">243</h2>
                <h2 className="h2">+</h2>
            </div>
            <h5 className="h5">Clients</h5>
      </div>
      <div className="counter-div d-flex flex-column align-items-center justify-content-center gap-2">
            <div className="d-flex counter_head">
                <h2 className="h2">70</h2>
                <h2 className="h2">+</h2>
            </div>
            <h5 className="h5">Projects</h5>
      </div>
      <div className="counter-div d-flex flex-column align-items-center justify-content-center gap-2">
            <div className="d-flex counter_head">
                <h2 className="h2">80</h2>
                <h2 className="h2">+</h2>
            </div>
            <h5 className="h5">Robots</h5>
      </div>
      <div className="counter-div d-flex flex-column align-items-center justify-content-center gap-2">
            <div className="d-flex counter_head">
                <h2 className="h2">240</h2>
                <h2 className="h2">+</h2>
            </div>
            <h5 className="h5">Clients</h5>
      </div>
    </div>
  );
}
