import React from 'react'
import DiscoverImg from '../../assets/images/img/discover robot.gif'

export default function Discover() {
  return (
    <div className="discover-bg">
    <section className="home-discover">
      <div className="row m-0">
        <div className="col-md-6 p-0">
            <div>
                <img src={DiscoverImg} alt="discover image" />
            </div>
        </div>
        <div className="col-md-6">
            <div className="d-flex flex-column gap-3 gap-md-5 h-100 justify-content-center">
                <h5 className="h5">Development Is The New Big Thing In <span>Technology</span></h5>
                <button className="discover-btn">Discover more</button>
            </div>
        </div>
      </div>
    </section>
    </div>
  )
}
