import React from "react";
import Pricing_icon from "../../assets/images/icons/pricing-icon.png";
export default function Pricing() {
  return (
    <div className="pricing-section">
      <div className="container">
        <div className="d-flex flex-column gap-2 ">
          <div className="d-flex gap-2 justify-content-center">
            <hr />
            <h3 className="h3 text-white m-0 p-1">Pricing Plan</h3>
            <hr />
          </div>
          <h2 className="h2 text-white pricing-title">
            Our <span>Pricing Plan</span>
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div className="pricing-box-div">
                <div className="pricing-box d-flex justify-content-center">
                  <div className="pricing-box-main d-flex flex-column gap-2 gap-sm-3 gap-md-5">
                        <div  className="d-flex flex-column gap-2">
                            <div className="d-flex flex-column gap-1 align-items-center">
                                <img src={Pricing_icon} alt="Pricing icon" />
                                <h3 className="h3 text-white">Basic</h3>
                            </div>
                            <div className="packages-benefits d-flex flex-column gap-sm-1 gap-md-2">
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-1 gap-sm-2 gap-md-3 align-items-center">
                            <h2 className="h2">$2.99</h2>
                            <div className="pricing-btn-div">
                            <button className="pricing-btn">Get a Quote</button>
                            </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="pricing-box-div">
                <div className="pricing-box d-flex justify-content-center">
                  <div className="pricing-box-main d-flex flex-column gap-2 gap-sm-3 gap-md-5">
                        <div  className="d-flex flex-column gap-2">
                            <div className="d-flex flex-column gap-1 align-items-center">
                                <img src={Pricing_icon} alt="Pricing icon" />
                                <h3 className="h3 text-white">Premium</h3>
                            </div>
                            <div className="packages-benefits d-flex flex-column gap-sm-1 gap-md-2">
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                                   <div className="d-flex gap-3">
                                         <i className="fa-solid fa-check pricing-check-icon"></i>
                                        <p> Lorem Ipsum dolor sit</p>
                                    </div> 
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-1 gap-sm-2 gap-md-3 align-items-center">
                            <h2 className="h2">$4.99</h2>
                            <div className="pricing-btn-div">
                            <button className="pricing-btn">Get a Quote</button>
                            </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
