import React from 'react'
import inverted_comma from "../../assets/images/icons/inverted comma.png";
import testimonial1 from "../../assets/images/img/testimonial1.png";
import testimonial2 from "../../assets/images/img/testimonial2.png";
import testimonial3 from "../../assets/images/img/testimonial3.png";
import testimonial4 from "../../assets/images/img/testimonial4.png";

export default function Testimonial_page_section() {
  return (
    <div className="main-bg contact-page">
        <div className="about-bg d-flex justify-content-center align-items-center">
        <h1 className="h1 text-white">Testimonial</h1>
        </div>
        <div id="testimonial-section">
            <div className="container  d-flex flex-column gap-3 gap-md-4 gap-lg-5">
                    <div className="testimonial-head d-flex flex-column justify-content-center align-items-center gap-2 gap-md-3 gap-xxl-4">
                        <div className="d-flex gap-2">
                            <hr />
                            <h3 className="h3 text-white m-0">Testimonial</h3>
                            <hr />
                        </div>
                        <h5 className="h5 text-white">Succour The <span>Customer Better</span></h5>
                        <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                        <div className='testimonial-cards'>
                            
                                
                                    <div className="testimon-card-div">
                                        <div className="testimon-card">
                                            <div className="testimonial">
                                            <div className="d-flex justify-content-between">
                                                <div className="person-div">
                                                <div className="person">
                                                    <img src={testimonial1} alt="testimonial" />
                                                </div>
                                                </div>
                                                <div>
                                                <img src={inverted_comma} alt="inverted comma" />
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className="h5 text-white pt-5">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry. Lorem Ipsum has been the industry.
                                                </h5>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimon-card-div">
                                        <div className="testimon-card">
                                            <div className="testimonial">
                                            <div className="d-flex justify-content-between">
                                                <div className="person-div">
                                                <div className="person">
                                                    <img src={testimonial2} alt="testimonial" />
                                                </div>
                                                </div>
                                                <div>
                                                <img src={inverted_comma} alt="inverted comma" />
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className="h5 text-white pt-5">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry. Lorem Ipsum has been the industry.
                                                </h5>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimon-card-div">
                                        <div className="testimon-card">
                                            <div className="testimonial">
                                            <div className="d-flex justify-content-between">
                                                <div className="person-div">
                                                <div className="person">
                                                    <img src={testimonial3} alt="testimonial" />
                                                </div>
                                                </div>
                                                <div>
                                                <img src={inverted_comma} alt="inverted comma" />
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className="h5 text-white pt-5">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry. Lorem Ipsum has been the industry.
                                                </h5>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimon-card-div">
                                        <div className="testimon-card">
                                            <div className="testimonial">
                                            <div className="d-flex justify-content-between">
                                                <div className="person-div">
                                                <div className="person">
                                                    <img src={testimonial4} alt="testimonial" />
                                                </div>
                                                </div>
                                                <div>
                                                <img src={inverted_comma} alt="inverted comma" />
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className="h5 text-white pt-5">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry. Lorem Ipsum has been the industry.
                                                </h5>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                
                                
                            
                        </div>
            </div>
        </div>
    </div>
  )
}
