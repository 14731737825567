import React from 'react'
import Contact from './Contact'
import Contactform from '../home/Contact'
import Navbar from '../common/Navbar'
import Newsletter from '../common/Newsletter'
import Footer from '../common/Footer'

export default function Contact_page() {
  return (
    <div>
      <Navbar/>
      <Contact/>
      <Contactform/>
      <Newsletter/>
      <Footer/>
    </div>
  )
}
