import React from "react";
import AboutUs from "../home/About_Us";
import Discover from "../home/Discover";
import WhyUs from "./Why_Us";
import Team from "../home/Team";
import Testimonial from "../home/Testimonial";
import OurProject from "../home/Our_Project";
import Pricing from "../home/Pricing";
import Faq from "../home/Faq_section";
import Contact from "../home/Contact";
import Insights from "../home/Insights";
import Navbar from "../common/Navbar";
import Newsletter from "../common/Newsletter";
import Footer from "../common/Footer";
export default function About_Us() {
  return (
    <>
      <div className="main-bg ">
        <Navbar/>
        <div className="about-bg d-flex justify-content-center align-items-center">
          <h1 className="h1 text-white">About Us</h1>
        </div>
        <AboutUs />
        <WhyUs />
        <Discover />
        <Team />
        <Testimonial/>
        <OurProject/>
        <Pricing/>
        <Faq/>
        <Contact/>
        <Insights/>
        <Newsletter/>
        <Footer/>
      </div>
    </>
  );
}
