import React from 'react'
import Testimonial_page_section from './Testimonial_page_section'
import Counter from '../home/Counter'
import Faq_section from '../home/Faq_section'
import Navbar from '../common/Navbar'
import Newsletter from '../common/Newsletter'
import Footer from '../common/Footer'

export default function Testimonial_page() {
  return (
    <div className='bg-clr'>
      <Navbar/>
      <Testimonial_page_section/>
      <Counter/>
      <Faq_section/>
      <Newsletter/>
      <Footer/>
    </div>
  )
}
