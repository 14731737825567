import React, { useState, useEffect, useRef } from "react";
import Logo from "../../assets/images/logo/Asset 3.png";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import { NavLink , Link } from "react-router-dom";


export default function Navbar() {
  const [bodyOverflow, setBodyOverflow] = useState("auto");
  const [asideMenu, setAsideMenu] = useState(false);
  const asideRef = useRef(null);

  // Toggle the overflow property
  const handleToggleMenu = () => {
    setAsideMenu(!asideMenu);
    setBodyOverflow(asideMenu ? "auto" : "hidden");
  };

  // Effect to update the body's overflow property
  useEffect(() => {
    document.body.style.overflow = bodyOverflow;
  }, [bodyOverflow]);

  // Close the menu if clicked outside of aside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (asideRef.current && !asideRef.current.contains(event.target)) {
        setAsideMenu(false);
        setBodyOverflow("auto");
      }
    };

    if (asideMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [asideMenu]);

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container pt-3 d-flex flex-row justify-content-between align-items-center">
            <div className="w-100 d-flex flex-row justify-content-between align-items-center">
              <Link  to="/">
              <img src={Logo} alt="header logo" className="w-25 h-25" to="/"/>
              </Link>
              <div className="nav-toggler">
                <i className="fa-solid fa-bars d-block d-sm-block d-md-block d-lg-none d-xl-none d-xx-none" id="menu-icon" onClick={handleToggleMenu}></i>
              </div>
            </div>
            <div>
              <ul className="d-flex gap-4 gap-xl-5 nav-items m-0 align-items-center d-none d-sm-none d-md-none d-lg-flex d-xl-flex d-xx-flex" id="navbar-contact">
                <li className="nav-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about_us">About us</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/services">Services</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="contact">Contact</NavLink>
                </li>
                <div className="btn-group">
                  <li type="button" className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                    More
                  </li>
                  <ul className="dropdown-menu dropdown-menu-lg-end">
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/project">
                        Project
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/team">
                        Team
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/faq">
                        FAQ
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/testimonial">
                        Testimonial
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/pricing_plan">
                        Pricing plan
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/blog">
                        Blog
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/coming_soon">
                        Coming soon
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" type="button" to="/404">
                        404
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <li className="nav-item-last">
                  <NavLink to="#" className="nav-NavLink btn-hover">
                    Contact us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <aside ref={asideRef} className={asideMenu ? "aside active" : "aside"}>
        <nav>
          <div>
            <div id="sidenav-Logo" className="d-flex justify-content-between w-100 align-items-center">
              <img src={Logo} alt="aside logo" />
              <i className="fa-solid fa-x" id="close-icon" onClick={handleToggleMenu}></i>
            </div>
            <div className="aside-navbar d-flex flex-column justify-content-start align-items-start">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about_us">About us</NavLink>
              <NavLink to="/services">Services</NavLink>
              <NavLink to="#/contact">Contact</NavLink>

              <div className=" aside-dropdown d-flex flex-column justify-content-start align-items-start">
                <div className="w-100 d-flex justify-content-between">
                  <NavLink to="#">More</NavLink> <i className="fa-solid fa-plus"></i>
                </div>
                <div className="aside-dropdown-items d-flex flex-column justify-content-start align-items-start">
                  <NavLink to="/project">Project</NavLink>
                  <NavLink to="/team">Team</NavLink>
                  <NavLink to="/faq">FAQ</NavLink>
                  <NavLink to="/testimonial">Testimonial</NavLink>
                  <NavLink to="/pricing">Pricing plan</NavLink>
                  <NavLink to="/blog">Blog</NavLink>
                  <NavLink to="/coming_soon">Coming soon</NavLink>
                  <NavLink to="/404">404</NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </aside>
    </div>
  );
}
