import React from "react";
import Navbar from "./common/Navbar";
import Main from "./home/Main";
import Services from "./home/Services";
import Testimonial from "./home/Testimonial";
import Counter from "./home/Counter";
import AboutUs from "./home/About_Us";
import Discover from "./home/Discover";
import Team from "./home/Team";
import OurProject from "./home/Our_Project";
import Pricing from "./home/Pricing";
import Faq from "./home/Faq_section";
import Contact from "./home/Contact";
import Insights from "./home/Insights";
import Newsletter from "./common/Newsletter";
import Footer from "./common/Footer";

export default function Home() {
  return (
    <>
      <div className="main-bg">
        <Navbar/>
        <Main />
        <Services />
        <Counter />
        <AboutUs />
        <Discover />
        <Team />
        <Testimonial />
        <OurProject />
        <Pricing />
        <Faq />
        <Contact />
        <Insights />
        <Newsletter/>
        <Footer/>
      </div>
    </>
  );
}
