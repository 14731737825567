import React from "react";
import project3 from "../../assets/images/img/project3.png";
import project1 from "../../assets/images/img/project1.png";
import project4 from "../../assets/images/img/project4.png";
import project5 from "../../assets/images/img/project5.png";
import detail_icon1 from "../../assets/images/icons/about-us-icon-1.png";
import detail_icon2 from "../../assets/images/icons/about-us-icon-2.png";
import Navbar from "../common/Navbar";
import Newsletter from "../common/Newsletter";
import Footer from "../common/Footer";

export default function Project() {
  return (
    <div className="main-bg ">
      <Navbar/>
      <div className="about-bg d-flex justify-content-center align-items-center">
        <h1 className="h1 text-white">Project</h1>
      </div>
      <div className="container">
        <div className="projects-section ">
          <div className="project1 d-flex flex-column gap-3">
            <div className="d-flex gap-2 justify-content-center">
              <hr />
              <h3 className="h3 text-white m-0">Projects</h3>
              <hr />
            </div>
            <h1 className="h1 text-white text-center">
              Our <span>Project</span>
            </h1>
            <div className="project1-section">
              <div className="row m-0">
                <div className="col-md-6 bg d-flex flex-column justify-content-center align-items-center">
                  <img src={project3} alt="project image" />
                </div>
                <div className="col-md-6 project-details d-flex flex-column gap-2">
                  <h2 className="h2">Avation EMF-01</h2>
                  <p className=" text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <div className="d-flex flex-column gap-3 gap-xxl-4">
                    <div className="d-flex gap-3 align-items-center">
                      <img src={detail_icon1} alt="detail icons" />
                      <h4 className="h4 text-white">Smart Solutions</h4>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <img src={detail_icon2} alt="detail icons" />
                      <h4 className="h4 text-white">Certified Expert</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="project2 bg2 project2-section">
        <div className="container">
          <div className="row m-0">
            <div className="col-md-6 project-details d-flex flex-column gap-2">
              <h2 className="h2">Avation EMF-02</h2>
              <p className=" text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              <div className="d-flex flex-column gap-3 gap-xxl-4">
                <div className="d-flex gap-3 align-items-center">
                  <img src={detail_icon1} alt="detail icons" />
                  <h4 className="h4 text-white">Smart Solutions</h4>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  <img src={detail_icon2} alt="detail icons" />
                  <h4 className="h4 text-white">Certified Expert</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 bg d-flex flex-column justify-content-center align-items-center">
              <img src={project1} alt="project image" />
            </div>
          </div>
        </div>
      </div>
      <div className="projects">
        <div className="container">
          <div className="row m-0">
            <div className="col-md-6 bg d-flex flex-column justify-content-center align-items-center">
              <img src={project4} alt="project image" />
            </div>
            <div className="col-md-6 project-details d-flex flex-column gap-2">
              <h2 className="h2">Avation EMF-03</h2>
              <p className=" text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              <div className="d-flex flex-column gap-3 gap-xxl-4">
                <div className="d-flex gap-3 align-items-center">
                  <img src={detail_icon1} alt="detail icons" />
                  <h4 className="h4 text-white">Smart Solutions</h4>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  <img src={detail_icon2} alt="detail icons" />
                  <h4 className="h4 text-white">Certified Expert</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="project2 bg2 project4-section">
        <div className="container">
          <div className="row m-0">
            <div className="col-md-6 project-details d-flex flex-column gap-2">
              <h2 className="h2">Avation EMF-02</h2>
              <p className=" text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              <div className="d-flex flex-column gap-3 gap-xxl-4">
                <div className="d-flex gap-3 align-items-center">
                  <img src={detail_icon1} alt="detail icons" />
                  <h4 className="h4 text-white">Smart Solutions</h4>
                </div>
                <div className="d-flex gap-3 align-items-center">
                  <img src={detail_icon2} alt="detail icons" />
                  <h4 className="h4 text-white">Certified Expert</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 bg d-flex flex-column justify-content-center align-items-center">
              <img src={project5} alt="project image" />
            </div>
          </div>
        </div>
      </div>
      <Newsletter/>
      <Footer/>
    </div>
  );
}
