import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes, createBrowserRouter } from "react-router-dom";
import "./App.css";
import About_Us from "./Component/About/About_Us";
import Home from "./Component/Home";
import Services from "./Component/Services/Services";
import Contact from "./Component/Contact/Contact_page";
import Project from "./Component/Project/Project";
import Team_page from "./Component/Team/Team_page";
import Faq_page from "./Component/FAQ/Faq_page";
import Testimonial_page from "./Component/Testimonial/Testimonial_page";
import Pricing_Plan from "./Component/Pricing_Plan/Pricing_Plan";
import Insights_page from "./Component/Insights/Insights_page";
import Coming_soon from "./Component/Coming_soon/Coming_soon";
import NotFound from "./Component/NotFound/NotFound";
const router = createBrowserRouter([
  {path:"/", element:<Home/>},
  {path:"about_us", element:<About_Us/>},
  {path:"services", element:<Services/>},
  {path:"contact", element:<Contact/>},
  {path:"project", element:<Project/>},
  {path:"team", element:<Team_page/>},
  {path:"faq", element:<Faq_page/>},
  {path:"testimonial", element:<Testimonial_page/>},
  {path:"pricing_plan", element:<Pricing_Plan/>},
  {path:"blog", element:<Insights_page/>},
  {path:"coming_soon", element:<Coming_soon/>},
  {path:"404", element:<NotFound/>},
  {path:"*", element:<NotFound/>},

])

function App() {
  return (
    <RouterProvider router ={router} />
    // <Router>
    //   <div className="">
    //     <Routes>
    //       <Route path="/" element={<Home />} />
    //       <Route path="/about_us" element={<About_Us />} />
    //       <Route path="/services" element={<Services />} />
    //       <Route path="/contact" element={<Contact />} />
    //       <Route path="/project" element={<Project />} />
    //       <Route path="/team" element={<Team_page />} />
    //       <Route path="/faq" element={<Faq_page />} />
    //       <Route path="/testimonial" element={<Testimonial_page />} />
    //       <Route path="/pricing_plan" element={<Pricing_Plan />} />
    //       <Route path="/blog" element={<Insights_page />} />
    //       <Route path="/coming_soon" element={<Coming_soon />} />
    //       <Route exact path="404" element={<NotFound />} />
    //     </Routes>
    //   </div>
    // </Router>
  );
}

export default App;
