import React from "react";
// import card1_icon from "../assets/images/icons/card1.png"

export default function Card(props) {
  return (
    <div className="h-100">
      <div className="container cards h-100">
        <div className="row h-100">
            <div className="col-lg-6">
                <img className="w-100 h-100" src={props.card_main_image} alt="card1 photo" />
            </div>
            <div className="col-lg-6">
                    <div className="heading d-flex align-items-center gap-2">
                          {props.card_icon && (
                              <img src={props.card_icon} alt="card-icon" />
                            )}
                        {/* <img src={props.card_icon}alt="card-icon" /> */}
                        <h3 className="h3 text-white m-0">{props.card_title}</h3>

                    </div>
                    <p className="card-para text-white">{props.discription}</p>
                    <a href="" className="card-learn-more text-decoration-none d-flex align-items-center gap-1">Learn more<i class="fa-solid fa-arrow-right"></i></a>
            </div>
        </div>
      </div>
    </div>
  );
}




